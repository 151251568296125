import { graphql } from 'gatsby';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Box from '../components/box';
import IntroTextWrapper from '../components/intro-text-wrapper';
import ImageLink from '../components/image-link';
import CaptionCardWrapper from '../components/caption-card-wrapper';
import CaptionCard from '../components/caption-card';
import LinkPanel from '../components/link-panel';
import Subscribe from '../components/subscribe';
import PagePaddingTop from '../components/page-padding-top';

export default function Ventures({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setHeaderHide(false);
    theme.setLogoHide(false);
    theme.setLogoInvert(false);
    theme.setBackURL('/us');
  }, []);

  return (
    <PagePaddingTop>
      <SEO
        title="Ventures"
        description={
          data.ventures._rawIntroText
            ? data.ventures._rawIntroText
                .map((el) => el.children)
                .flat()
                .map((el) => el?.text)
                .join(' ')
            : null
        }
      />
      <Box
        x={10}
        y={6}
        css={css`
          & + div {
            margin-top: 6rem;
          }
        `}
      >
        <IntroTextWrapper>
          <BlockContent content={data.ventures._rawIntroText} />
        </IntroTextWrapper>
      </Box>
      {data.ventures.ventures.length > 0 &&
        data.ventures.ventures.map((venture) => (
          <Box x={10} y={15}>
            <CaptionCardWrapper>
              <ImageLink text={venture.text} url={venture.url} image={venture.image} />
              {venture.captionCard.hasCard && (
                <CaptionCard
                  text={venture.captionCard._rawCaption}
                  linkText={venture.captionCard.linkText}
                  url={venture.captionCard.linkURL}
                  position={venture.captionCard.position}
                  indent={2}
                />
              )}
            </CaptionCardWrapper>
          </Box>
        ))}
      <Box
        y={10}
        css={css`
          margin-bottom: 0;

          @media (max-width: 800px) {
            margin-bottom: 0;
          }

          @media (max-width: 550px) {
            margin-bottom: 0;
          }
        `}
      >
        <Subscribe />
      </Box>
      <LinkPanel
        content={[
          {
            text: 'Studio',
            url: '/studio',
          },
          {
            text: 'People',
            url: '/people',
          },
        ]}
      />
    </PagePaddingTop>
  );
}

export const query = graphql`
  query {
    ventures: sanityVentures(_id: { regex: "/(drafts\\\\.ventures)?/" }) {
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      ventures {
        image {
          ...Image
        }
        text
        url
        captionCard {
          hasCard
          _rawCaption(resolveReferences: { maxDepth: 10 })
          linkText
          linkURL
          position
        }
      }
    }
  }
`;
